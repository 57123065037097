import { useEffect, useState } from "react";
import { JobsTable, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import http from '../../net/http-common';
import {
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  Tab,
  Card,
  CardContent,
} from "@mui/material";
import Loaders from "../../components/common/Loaders";
import TableRowsIcon from '@mui/icons-material/TableRows';
import TuneIcon from '@mui/icons-material/Tune';

import { JOB_SERVER_URL } from "../../config";

import LiftVisualizerInput from "./LiftVisualizerInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import LiftVisualLigandInput from "./LiftVisualLigandInput";
 

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography>
          The LIFT Visualizer computes non-covalent interactions between the
          protein and ligand in a given complex for all binding sites. It
          provides a detailed description of each interactiom, e.g. - Hydrogen
          Bonds, Salt Bridges etc. along with the residue, chain name, distance
          and coordinates of the atoms involved.
        </Typography>
        <Box
          component="img"
          src="/images/hero/lift_visualization _home.png"
          alt="Protein Info Visual"
          sx={{
            width: "100%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },

];

function LiftVisualizer() {
 
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [tabValue, setTabValue] = useState("1");
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };


 
  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid px={3} container spacing={2.5} mt={-1} mb={2}>
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>

        <Card sx={{width:"100%"}}>
        <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    
                  >
                    <Tab label="Protein" value="1"  />
                    <Tab label="Protein + Ligand" value="2" />

                  </TabList>
                </Box>
                {/* Predictions */}
                <TabPanel value="1" sx={{padding:0}}>
          <LiftVisualizerInput />
                   
                </TabPanel>
                <TabPanel value="2"  sx={{padding:0}} >
                <LiftVisualLigandInput />
                   
                </TabPanel>
              </TabContext>
        </Card>


        </Grid>
      </Grid>
       
    </>
  );
}

export default LiftVisualizer;
