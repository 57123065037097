import React, { useRef, useState, useEffect } from "react";
import {
  DataGridPro,
  GridColDef,
  useGridApiRef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridRowParams,
  GridFilterOperator,
  GridFilterItem,
  getGridNumericOperators,
} from "@mui/x-data-grid-pro";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { tableStateType } from "../AdmeTox/ADMEPropertyFilter";
import { useNavigate } from "react-router-dom";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { setSinglesmileData } from "./propertiesSmilesStringSliceV3";
import {
  getScreenieRequirements,
  setScreenieRequirements,
} from "./propertiesHelper";
import InputNumberInterval from "../../components/Properties/InputNumberInterval";
import ScreenieGridView from "../../components/Properties/ScreenieGridView";
import WindowIcon from "@mui/icons-material/Window";

// custom toolbar with custom default filters
export const CustomToolbar = ({
  props,
  isExport = true,
}: {
  props: any;
  isExport?: boolean;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {isExport && (
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true,
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

function PropertiesVisualizeV3() {
  const [tabValue, setTabValue] = useState("1");
  const [pcpRow, setPcpRow] = useState<any[]>([]);
  const [medchemRow, setMedchemRow] = useState<any[]>([]);
  const [maiFRow, setMaiFRow] = useState<any[]>([]);
  const [toxicRow, setToxicRow] = useState<any[]>([]);

  const [pcpCol, setPcpCol] = useState<any[]>([]);
  const [medchemCol, setMedchemCol] = useState<any[]>([]);
  const [maiFCol, setMaiFCol] = useState<any[]>([]);
  const [toxicCol, setToxicCol] = useState<any[]>([]);

  const [allCols, setAllCols] = useState<GridColDef[]>([]);
  const [allRows, setAllRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gridFilters, setGridFilters] = useState<any>(null);

  // all filters
  const [tablePCPFilters, setTablePCPFilters] = useState<any>(null);
  const [tableMedChemFilters, setTableMedChemFilters] = useState<any>(null);
  const [tableMaiFilters, setTableMaiFilters] = useState<any>(null);
  const [tableToxiFilters, setTableToxicFilters] = useState<any>(null);
  const [tableAllFilters, setTableAllFilters] = useState<any>(null);

  const scrollToRef = useRef(null);

  const [alignment, setAlignment] = React.useState("grid");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [densityAndRows, setDensityAndRows] = React.useState<tableStateType>({
    density: "standard",
    pageSize: 10,
  });

  let table = useGridApiRef();
  const screeniePropertyV3 = useAppSelector(
    (state: any) => state.screeniePropertyV3.properties
  );
  const screenieSingleSmilePropertyv3 = useAppSelector(
    (state: any) => state.propertiesV3.properties
  );

  // change tab value
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setScreenieRequirements("tab", newValue);
  };

  // convert true false to PASS and FAIL
  const getTrueFalseForMai = (params: GridRenderCellParams<any, string>) => {
    if (params.value === undefined || params.value === null) {
      return <div>...</div>;
    }

    let medVal;

    if (typeof params.value === "boolean") {
      medVal = [params.value];
    } else if (typeof params.value === "string") {
      try {
        const validArrayString = params.value
          .replace(/'/g, '"')
          .replace(/True/g, "true")
          .replace(/False/g, "false");

        medVal = JSON.parse(validArrayString);
      } catch (error) {
        console.error("Error parsing value:", error);
        return <div>Parsing error</div>;
      }
    } else if (Array.isArray(params.value)) {
      medVal = params.value;
    } else {
      return <div>Invalid data</div>;
    }

    return medVal[0] ? "PASS" : "FAIL";
  };

  const getTrueFalseForToxic = (params: GridRenderCellParams<any, number>) => {
    return <>{params.value}</>;
  };

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;

    dispatch(
      setSinglesmileData({
        physico_chemical_properties: pcpRow.find(
          (i) => i.smiles === params.row.smiles
        ),
        medchem_filters: medchemRow.find((i) => i.smiles === params.row.smiles),
        mai_filters: maiFRow.find((i) => i.smiles === params.row.smiles),
        toxic_filters: toxicRow.find((i) => i.smiles === params.row.smiles),
      })
    );
    navigate("/virtual-screening/properties-v3/evaluations");
  };

  const BetweenOperator: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    ...getGridNumericOperators(),
  ];

  // physico chemical properties columns
  const physicoChemicalPropColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },

    // {
    //     field: "MolLogP",
    //     headerName: "MolLogP",
    //     type: "number",
    //     editable: false,
    //     width: 120,
    //     headerAlign: "center",
    //     align: "center",
    //   },
    {
      field: "MolLogP",
      headerName: "MolLogP",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "MolWt",
      headerName: "MolWt",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumRotatableBonds",
      headerName: "NumRotatableBonds",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondDonors",
      headerName: "HBondDonors",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondAcceptors",
      headerName: "HBondAcceptors",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterOperators: BetweenOperator,
    },
    {
      field: "TPSA",
      headerName: "TPSA",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "HeavyAtoms",
      headerName: "HeavyAtoms",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumAromaticRings",
      headerName: "NumAromaticRings",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterOperators: BetweenOperator,
    },
    // {
    //   field: "SASA",
    //   headerName: "SASA",
    //   type: "number",
    //   headerAlign: "center",
    //   align: "center",
    //   filterOperators: BetweenOperator,
    // },
    {
      field: "QED",
      headerName: "QED",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "SA",
      headerName: "SA",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "UndesiredRings",
      headerName: "UndesiredRings",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterOperators: BetweenOperator,
    },
  ];

  // medicinal chemistry properties columns
  const medchemFiltersColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "Ghose",
      headerName: "Ghose",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Veber",
      headerName: "Veber",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Egan",
      headerName: "Egan",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Muegge",
      headerName: "Muegge",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Oprea",
      headerName: "Oprea",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "REOS",
      headerName: "REOS",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Mozziconacci",
      headerName: "Mozziconacci",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
      width: 150,
    },
    {
      field: "Palm",
      headerName: "Palm",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Pfizer3/75",
      headerName: "Pfizer3/75",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
    {
      field: "Lipinski",
      headerName: "Lipinski",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForMai,
    },
  ];

  // Mai filter properties columns
  const maiFiltersColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    // {
    //   field: "PAINS",
    //   headerName: "PAINS",
    //   type: "string",
    //   editable: false,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "MAI Lipinski+",
      headerName: "MAI Lipinski+",
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "Unwanted Substructures",
      headerName: "Unwanted Substructures",
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "MAI Rings",
      headerName: "MAI Rings",
      type: "string",
      headerAlign: "center",
      align: "center",
    },
  ];

  // Mai filter properties columns
  const toxicFiltersColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "BRENK",
      headerName: "BRENK",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },
    {
      field: "NIH",
      headerName: "NIH",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },
    {
      field: "ZINC",
      headerName: "ZINC",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },

    {
      field: "PAINS_A",
      headerName: "PAINS_A",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },
    {
      field: "PAINS_B",
      headerName: "PAINS_B",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },
    {
      field: "PAINS_C",
      headerName: "PAINS_C",
      type: "string",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: getTrueFalseForToxic,
    },
  ];

  const handleScroll = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTableStateChange = (
    newState: any,
    setFilterState: React.Dispatch<any>
  ) => {
    setFilterState(newState.filter.filterModel);
  };

  const findKeysType = (obj: any, key: any) => {
    return typeof obj[key];
  };

  useEffect(() => {
    if (screeniePropertyV3.data) {
      // if tables data is present then set all rows of each tab panel
      setIsLoading(true);
      if ("name" in screeniePropertyV3.data[4][0]) {
        const nameColumn: GridColDef = {
          field: "name",
          headerName: "Name",
          headerAlign: "center",
          type: "string",
          align: "center",
          width: 150,
        };

        physicoChemicalPropColumns.splice(1, 0, nameColumn);
        medchemFiltersColumns.splice(1, 0, nameColumn);
        toxicFiltersColumn.splice(1, 0, nameColumn);
        maiFiltersColumns.splice(1, 0, nameColumn);
      }

      setPcpCol(physicoChemicalPropColumns);
      setMedchemCol(medchemFiltersColumns);
      setMaiFCol(maiFiltersColumns);
      setToxicCol(toxicFiltersColumn);

      setPcpRow(screeniePropertyV3.data[0]);
      setMedchemRow(screeniePropertyV3.data[1]);
      setMaiFRow(screeniePropertyV3.data[2]);
      setToxicRow(screeniePropertyV3.data[3]);
      setAllRows(screeniePropertyV3.data[4]);

      // merging all the columns to all properies and filters
      const filteredMedCols = medchemFiltersColumns.filter(
        (col) =>
          col.field !== "svg" &&
          col.field !== "smiles" &&
          col.field !== "id" &&
          col.field != "name"
      );

      const filteredMaiCols = toxicFiltersColumn.filter(
        (col) =>
          col.field !== "svg" &&
          col.field !== "smiles" &&
          col.field !== "id" &&
          col.field != "name"
      );

      const filteredToxicCols = maiFiltersColumns.filter(
        (col) =>
          col.field !== "svg" &&
          col.field !== "smiles" &&
          col.field !== "id" &&
          col.field != "name"
      );
      const mergedCols = [
        ...physicoChemicalPropColumns,
        ...filteredMedCols,
        ...filteredMaiCols,
        ...filteredToxicCols,
      ];

      // Get predefined column fields
      const predefinedFields = mergedCols.map((col) => col.field);
      const fieldsToRemove = ["smiles", "SMILES", "mol2","SASA"];
      predefinedFields.push(...fieldsToRemove);

    

      // Identify dynamic fields (User Defined Field)
      const dynamicFields = screeniePropertyV3.data[4].reduce(
        (fields: any, item: any) => {
          Object.keys(item).forEach((key) => {
            if (!predefinedFields.includes(key) && !fields.includes(key)) {
              fields.push(key);
            }
          });
          return fields;
        },
        []
      );

     
      const firstObjectOfAllRows = screeniePropertyV3.data[4][0];
      // Create dynamic columns
      const dynamicColumns = dynamicFields.map((field: any) => ({
        field,
        headerName: field.toUpperCase(),
        headerAlign: "center",
        align: "center",
        width: 150,
        type: findKeysType(firstObjectOfAllRows, field),
        renderCell: (params: any) => {
          const typeOfValue = typeof params.value;
          if (typeOfValue === "boolean") {
            if (params.value) {
              return (
                <Tooltip title={"TRUE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={"FALSE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            }
          } else if (typeOfValue === "number") {
            return (
              <Tooltip title={params.value} placement="top" arrow>
                <div>{params.value.toFixed(3)}</div>
              </Tooltip>
            );
          }

          return (
            <Tooltip title={params.value} placement="top" arrow>
              <div>{params.value}</div>
            </Tooltip>
          );
        },
      }));

      // mergedCols.splice(1, 0, ...dynamicColumns);
      mergedCols.push(...dynamicColumns);

      setAllCols(mergedCols);
      setIsLoading(false);
    } else if (
      screeniePropertyV3.data === null &&
      !screeniePropertyV3.loading
    ) {
      navigate(-1);
    }

    // if density and rowssize already stored in the localstorage
    // const densityAndRowsOfTable = JSON.parse(
    //   localStorage.getItem("screenieTableState")
    // );

    // if (densityAndRowsOfTable) {
    //   setDensityAndRows(densityAndRowsOfTable);
    // }

    const tabNum = getScreenieRequirements("tab");

    if (tabNum !== null) {
      setTabValue(tabNum);
    }
  }, [screeniePropertyV3]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        aria-label="Platform"
        sx={{
          margin: "-1rem 1rem 0rem 3rem",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Button
          sx={{
            fontSize: 12,
            paddingTop: 1,
            paddingBottom: 1,
            mx: 1,
            backgroundColor: "#582FF1;",
            fontWeight: "bold",
          }}
          onClick={handleScroll}
          variant="contained"
          startIcon={<WindowIcon sx={{ fontSize: 12 }} />}
          size="large"
        >
          Grid View
        </Button>
      </ToggleButtonGroup>

      <Grid container spacing={2} p={2}>
        <Grid item container px={1} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Physicochemical Properties" value="1" />
                    <Tab label="MedChem Filters" value="2" />
                    <Tab label="MAI Filters" value="3" />

                    <Tab label="Toxic Filters" value="4" />
                    <Tab label="All properties and Filters" value="5" />
                  </TabList>
                </Box>
                {/* Predictions */}
                <TabPanel value="1">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: '550', // Force font weight to bold
                          fontSize:"0.9rem"
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight:"800 !important"
                        },
                      }}
                      rows={pcpRow}
                      // getRowId={(row) => (row.id ? row.id : uuidv4())}
                      columns={pcpCol}
                      onRowClick={handleRowClick}
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setTablePCPFilters)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: '550', // Force font weight to bold
                          fontSize:"0.9rem"
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={medchemRow}
                      // getRowId={(row) => (row.id ? row.id : uuidv4())}
                      columns={medchemCol}
                      onRowClick={handleRowClick}
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setTableMedChemFilters)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20,30]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="3">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: '550', // Force font weight to bold
                          fontSize:"0.9rem"
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={maiFRow}
                      // getRowId={(row) => (row.id ? row.id : uuidv4())}
                      columns={maiFCol}
                      onRowClick={handleRowClick}
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setTableMaiFilters)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20,30]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="4">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: '550', // Force font weight to bold
                          fontSize:"0.9rem"
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={toxicRow}
                      // getRowId={(row) => (row.id ? row.id : uuidv4())}
                      columns={toxicCol}
                      onRowClick={handleRowClick}
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setTableToxicFilters)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20,30]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="5">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: '550', // Force font weight to bold
                          fontSize:"0.9rem"
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={allRows}
                      // getRowId={(row) => (row.id ? row.id : uuidv4())}
                      columns={allCols}
                      onRowClick={handleRowClick}
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setTableAllFilters)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20,30]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Box ref={scrollToRef} pb={2}>
        <ScreenieGridView
          allRows={allRows}
          filters={[
            tablePCPFilters,
            tableMedChemFilters,
            tableMaiFilters,
            tableToxiFilters,
            tableAllFilters,
          ]}
          tabValue={tabValue}
          model="screenie"
        />
      </Box>

      <IndefiniteLoader state={screeniePropertyV3.loading} />
    </>
  );
}

export default PropertiesVisualizeV3;
