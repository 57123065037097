import {
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
  Alert,
  Snackbar,
  Grid,
  FormControl,
  LinearProgress,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { LIFT_SERVER_URL, RUNTIME, SERVER_URL } from "../../config";

import http from "../../net/http-common";

import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { FileUploader } from "../../components";

import { useNavigate } from "react-router-dom";

function LiftVisualLigandInput() {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [ligandsFile, setLigandsFile] = useState<File>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [ligandSmile, setLigandSmile] = useState("");

  const navigate = useNavigate();

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      case "sdf":
        setLigandsFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleSubmit = async (e: any) => {
    setInProgress(true);

    const form = new FormData();

    form.append("pdb_file", PDBFile);

    try {
      let response = {
        data: "",
      };

      if (ligandsFile) {
        form.append("ligand_file", ligandsFile);
        response = await http.post(
          `${LIFT_SERVER_URL}/LIFT_api/get_interaction_ligand_sdf`,
          form,
          {
            params: {
              residue_name: residue,
              protein_chain: protChain,
            },
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
          }
        );
      } else if (ligandSmile) {
         
        response = await http.post(
          `${LIFT_SERVER_URL}/LIFT_api/get_interaction_ligand_smiles`,
          form,
          {
            params: {
              residue_name: residue,
              protein_chain: protChain,
              ligand_smiles:ligandSmile
            },
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
          }
        );
      }
      if (Object.keys(response.data).length === 0) {
        setInProgress(false);

        setShowError(true);
        setErrorMsg("No data found for this PDB File");
        return;
      }

      const visualizerResult = response.data;

      navigate("result", { state: { visualizerResult } });
    } catch (error) {
      console.log("error is", error);
      setShowError(true);
      setErrorMsg("Facing Problem while Fetching Data");
    }
    setInProgress(false);
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType == "protein") {
      link.download = "lift_visualizer_sample2.pdb";
      link.href = "/samples/lift/4ffw.pdb";
    } else if (fileType == "ligand") {
      link.download = "lift_ligand_sample.sdf";
      link.href = "/samples/lift/4ffw_ligand.sdf";
    }

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 1, width: "100%" }}>
        <Grid
          container
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          display="flex"
          flexDirection="column"
        >
          <CardContent>
            <FormControl
              fullWidth
              className={RUNTIME === "PROD" ? "blurry-bg" : ""}
            >
              <Stack direction="column" spacing={1.5}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein File (.pdb)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("protein")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".pdb"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "pdb")
                  }
                  deleteHandlerDisable={() => {
                    return false;
                  }}
                />
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Residue Name
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={residues?.length === 0}
                    value={residue}
                    label="Residue"
                    onChange={(e) => setResidue(e.target.value)}
                  >
                    {residues?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein Chain
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={chains?.length === 0}
                    value={protChain}
                    label="Protein Chain"
                    onChange={(e) => setProtChain(e.target.value)}
                  >
                    {chains?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Stack
                direction="column"
                spacing={1.5}
                mt={1}
                p={2}
                sx={{ border: "1px solid #d7d6d6", borderRadius: "0.2rem" }}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Ligand File (.sdf)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("ligand")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".sdf"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "sdf")
                  }
                  deleteHandlerDisable={() => {
                    setLigandsFile(null);
                    return false;
                  }}
                />

                <Typography textAlign={"center"} my={1.5}>
                  -- OR --
                </Typography>

                <Typography component="label" htmlFor="smiles-string">
                  Enter a SMILES string
                </Typography>
                <TextField
                  placeholder="SMILES"
                  fullWidth
                  id="ligand-smile-id"
                  name="ligand-smile-id"
                  value={ligandSmile}
                  onChange={(e) => {
                    setLigandSmile(e.target.value);
                  }}
                />
              </Stack>

              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!PDBFile || (!ligandsFile && !ligandSmile)}
                sx={{
                  mt: 2,
                  "&.Mui-disabled": {
                    background: "#BDADF9",
                    color: "#fff",
                  },
                }}
              >
                Submit
              </Button>
            </FormControl>
          </CardContent>
        </Grid>
        <IndefiniteLoader state={inProgress} />
      </Box>
    </>
  );
}

export default LiftVisualLigandInput;
