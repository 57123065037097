import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Tooltip,
  Grid,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

type TableData = {
  title: string;
  value: string | boolean;
  color?: string;
  info: string;
  failReason?: string;
};

interface SectionData {
  heading: string;
  data: TableData[];
}

interface Props {
  screenie_data: SectionData[];
  molEditor?:boolean
}

const PropertiesTable: React.FC<Props> = ({ screenie_data,molEditor }) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#EDEBF9",
    },
    "& td": {
      padding: 4,
    },
  }));

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  return (
    <TableContainer style={{ overflow: "hidden", marginTop:"1em" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isSmallScreen || molEditor ? "1fr" : "repeat(2, 1fr)",
          gap: "15px",
        }}
      >
        {screenie_data.map((row: SectionData, index: number) => (
          <Grid key={index} item xs={12} >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{
                      backgroundColor: "rgb(196, 187, 249)",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "rgb(81, 59, 206)" }}
                      py={1}
                    >
                      {row.heading}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.data.length === 0 ? (
                  <StyledTableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      style={{ backgroundColor: "white", padding: 0 }}
                    >
                      <Typography py={0.2}>No data</Typography>
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  row.data.map((rowData, index) => (
                    <StyledTableRow key={index}>
                      <TableCell width="40%" className="text-left">
                        {rowData.title === "MAI"
                          ? "MAI Physicochemical Filter"
                          : rowData.title}
                      </TableCell>
                      <TableCell width="20%">
                        {typeof rowData.value === "string" ? (
                          <>
                            {(rowData.value.toUpperCase() === "PASS" ||
                              rowData.value.toUpperCase() === "TRUE") && (
                              <Tooltip title={"Pass"} placement="top" arrow>
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#4caf50", // Green color for PASS
                                  }}
                                />
                              </Tooltip>
                            )}
                            {(rowData.value.toUpperCase() === "FAIL" ||
                              rowData.value.toUpperCase() === "FALSE") && (
                              <Tooltip title={"Fail"} placement="top" arrow>
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#d95143", // Red color for FAIL
                                  }}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : typeof rowData.value === "boolean" ? (
                          <>
                            {rowData.value ? ( // Check if value is true
                              <Tooltip title={"Pass"} placement="top" arrow>
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#4caf50", // Green color for true
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={rowData.failReason}
                                placement="top"
                                arrow
                              >
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#d95143", // Red color for false
                                  }}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : typeof rowData.value === "number" &&
                          row.heading !== "Physicochemical Properties" ? (
                          <>
                            {rowData.value ? ( // Check if value is true
                              <Tooltip title={"Pass"} placement="top" arrow>
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#4caf50", // Green color for true
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title={"Fail"} placement="top" arrow>
                                <CircleIcon
                                  sx={{
                                    fontSize: 14,
                                    marginTop: 0.6,
                                    color: "#d95143", // Red color for false
                                  }}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : rowData.title === "MolWt" ? (
                          parseFloat(rowData.value).toFixed(0)
                        ) : rowData.title === "NumRotatableBonds" ||
                          rowData.title === "HBondAcceptors" ||
                          rowData.title === "HBondDonors" ||
                          rowData.title === "HeavyAtoms" ||
                          rowData.title === "NumAromaticRings" ? (
                          // Parse value as integer if title matches
                          parseInt(rowData.value).toString()
                        ) : (
                          parseFloat(rowData.value).toFixed(3)
                        )}
                      </TableCell>
                      <TableCell width="10%">
                        <Tooltip title={rowData.info} placement="top">
                          <InfoIcon sx={{ fontSize: 16, marginTop: 0.6 }} />
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Grid>
        ))}
      </div>
    </TableContainer>
  );
};

export default PropertiesTable;
