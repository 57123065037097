import React from "react";
import { SOLAR_DEV } from "../../config";

function TagMolChemlet() {
  return (
    <div className="TagMol">
      <iframe
        title="TagMOl"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/tagmol_app/`}
      ></iframe>
    </div>
  );
}

export default TagMolChemlet;
