import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Viewer from "../../components/common/DockViewer";
import { Color } from "molstar/lib/mol-util/color";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AutoDockViewer() {
  const navigate = useNavigate();
  const dockingModuleProperties = useSelector(
    (state: any) => state.dockingModule.properties
  );

  useEffect(() => {
    // console.log("ligand_url",dockingModuleProperties.ligand_url)
    if (!dockingModuleProperties.ligand_url) {
      navigate("/virtual-screening/autodock/");
    }
    var pdbqt1 =
      dockingModuleProperties.autoDockdata.protein_url ||
      "/samples/autodock/ace2.pdbqt";
    var pdbqt2 = dockingModuleProperties.ligand_url;
    // console.log("pdbqt2",pdbqt2);

    Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true).then(
      (viewer) => {
        viewer.loadStructuresFromUrlsAndMerge([
          { url: pdbqt1, format: "pdbqt" },
          { url: pdbqt2, format: "pdbqt" },
        ]);
      }
    );
  }, [dockingModuleProperties]);

  return (
    <>
      <Box
        width="79%"
        p={1}
        sx={{
          margin: "auto",
          backgroundColor: "#cce5ff",
          borderRadius: 1,
          border: "1.5px solid #b8daff",
          marginBottom: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              width="100%"
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                flexWrap: "wrap",
              }}
            >
              <Typography
              className="wrap-word"
                sx={{
                  color: "#004085",
                  fontWeight: 700,
                  fontSize: 18,
                 
                }}
              >
                {dockingModuleProperties.SMILES}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "rgb(61, 32, 168);",
                fontWeight: "bold",
              }}
            >
              Docking Score : {dockingModuleProperties.Docking_Score}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          m: 0,
          pl: 1,
          pr: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          id="molviewer"
          style={{
            height: "700px",
            width: "100%",
            position: "relative",
            display: "flex", // Add display flex
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        ></div>
      </Grid>
    </>
  );
}

export default AutoDockViewer;
