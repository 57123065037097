import { Card, Grid } from "@mui/material";

import { DashCard } from "../../components";

const dockingCards = [
  // {
  //   label: "DiffDock",
  //   link: "diffdock",
  //   description: "",
  //   dashIcon: "/images/dashcard-images/diffdock_dashcard.png",
  //   navlabel: "DiffDock",
  // },
  {
    label: "AutoDock",
    link: "autodock",
    description: "A widely adopted docking tool",
    dashIcon: "images/dashcard-images/dock2.png",
    navlabel: "AutoDock",
  },
  {
    label: "UniDock",
    link: "unidock",
    description: "Rapid docking for large-scale screening",
    dashIcon: "images/dashcard-images/dock2.png",
    navlabel: "UniDock",
  },
  {
    label: "Screenie",
    navlabel: "Screenie",
    link: "properties-v3",
    icon: "/images/icons/property-filter.svg",
    description: "Filter hit molecules based on physicochemical properties",
    dashIcon: "/images/dashcard-images/screenie-dashcard.png",
  },
  {
    label: "LIFT Visualizer",
    link: "lift/visualizer",
    description: "Visualize the interactions for each binding site.",
    dashIcon: "images/dashcard-images/lift_visualization.png",
    navlabel: "Lift Visualizer",
  },
  {
    label: "LIFT Filter Molecules",
    link: "lift/filter-molecules",
    description: "Filter molecules based on their conserved signature.",
    dashIcon: "images/dashcard-images/lift_visualization.png",
    navlabel: "Filter Molecules",
  },
  {
    label: "MolFil",
    navlabel: "Screenie",
    link: "molfil",
    icon: "/images/icons/property-filter.svg",
    description: "Molecule filtering based on our proprietary Neural Network.",
    dashIcon: "images/dashcard-images/molfil6.jpg",
  },
  {
    label: "GENFil",
    navlabel: "GENFil",
    link: "genfil",
    icon: "/images/icons/property-filter.svg",
    description: "Molecule filtering based on our proprietary Generative AI model",
    dashIcon: "images/dashcard-images/genfil3.png",
  },
];
function DockingDashboard() {
  return (
    <Grid container spacing={3} p={3}>
      {dockingCards.map((card, index) => (
        <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              flex: "1 0 0",
              borderRadius: "18px",
              border: "1px solid var(--shade-200, #E7EBF9)",
              height: "100%",
              "&:hover": {
                boxShadow: 7,
              },
            }}
          >
            <DashCard key={index} {...card} section="Docking" />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default DockingDashboard;
