import { SetStateAction, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";

import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { FileUploader, JobsTable, WorkflowLandingTabs } from "../../components";

import BetaModal from "../../components/common/BetaModal";
import { JOB_SERVER_URL, RUNTIME, SERVER_URL, SOLAR_DEV } from "../../config";
import Loaders from "../../components/common/Loaders";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

import http from "../../net/http-common";
import { WorkflowLandingTab } from "../../models";

interface AxiosConfigType {
  headers: {
    accept: string;
    "Content-Type": string;
    "Access-Control-Allow-Origin": string;
  };
  params?: {
    model_name: string;
  };
}

const autodockTabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          AutoDock Client, an automated docking platform, facilitates the
          virtual screening and seamless docking of lakhs of small molecules
          (ligands) to protein targets with little to no preparation, straight
          from the PDB-id of the target and the ligand's SMILE strings.
          Customized Python scripts are used to build the required protein and
          ligand’s “pdbqt” and “grid parameter” files, while an optimized Vina
          algorithm is used in the background to dock ligands.
        </Typography>
        <Box
          component="img"
          src="/images/dashcard-images/dock2.png"
          alt="Protein Info Visual"
          sx={{
            width: "40%", // Reduces the width to 75% of the parent container
            height: "auto",
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
        <Box>
          <Typography variant="h6">Not-supported:</Typography>
          <ol type="1">
            <li>Multimeric protein systems: The protein system in which ligand binding sites formed by multiple chains.</li>
            <li>Interfacial protein systems: The protein system in which the region of interest located at interface</li>
            <li>Apo-protein System. Unbound protein systems.</li>
            <li>Atoms including Boron (B) and Silicon (Si).</li>
            <li>Heavy atoms include Au, V, Cr, Co, Ni, Cu, Mo, Ru, Rh, Pd, Re, Os, Ir, and Pt.</li>
          </ol>
        </Box>
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Eberhardt, J., Santos-Martins, D., Tillack, A. F., & Forli, S.
          (2021). AutoDock Vina 1.2. 0: New docking methods, expanded force
          field, and python bindings. Journal of chemical information and
          modeling, 61(8), 3891-3898.
        </Typography>
      </>
    ),
  },
];

const unidockTabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          UniDock Client, an automated docking platform, facilitates the virtual
          screening and seamless docking of lakhs of small molecules (ligands)
          to protein targets with little to no preparation, straight from the
          PDB-id of the target and the ligand's SMILE strings. Customized Python
          scripts are used to build the required protein and ligand’s “pdbqt”
          and “grid parameter” files, while an optimized Vina algorithm is used
          in the background to dock ligands.
        </Typography>
        <Box
          component="img"
          src="/images/dashcard-images/dock2.png"
          alt="Protein Info Visual"
          sx={{
            width: "40%", // Reduces the width to 75% of the parent container
            height: "auto",
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
         <Box>
          <Typography variant="h6">Not-supported:</Typography>
          <ol type="1">
            <li>Multimeric protein systems: The protein system in which ligand binding sites formed by multiple chains.</li>
            <li>Interfacial protein systems: The protein system in which the region of interest located at interface</li>
            <li>Apo-protein System. Unbound protein systems.</li>
            <li>Atoms including Boron (B) and Silicon (Si).</li>
            <li>Heavy atoms include Au, V, Cr, Co, Ni, Cu, Mo, Ru, Rh, Pd, Re, Os, Ir, and Pt.</li>
          </ol>
        </Box>
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Yu, Y., Cai, C., Wang, J., Bo, Z., Zhu, Z., & Zheng, H. (2023).
          Uni-Dock: GPU-accelerated docking enables ultralarge virtual
          screening. Journal of chemical theory and computation, 19(11),
          3336-3345.
        </Typography>
      </>
    ),
  },
];

 

function DockingClient() {
  const MODEL_NAME = "autodock";

  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [ligandsFile, setLigandsFile] = useState<File>(null);
  const [ligandSmiles, setLigandSmiles] = useState("");
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [ligandColName, setLigandColName] = useState("smiles");
  const [inProgress, setInProgress] = useState(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [modelName, setModelName] = useState<string>("");
  const [tabs, setTabs] = useState<any>([]);

  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();
  const { user } = useUserAuth();
  const { pathname } = useLocation();

  const runInProd = RUNTIME === "PROD";

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      case "csv":
        setLigandsFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleDone = () => {
    setInProgress(false);
  };

  const onBetaClick = () => {
    setBetaOpen(true);
  };

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType == "protein") {
      link.download = "autodock_protein_sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    } else if (fileType == "ligand") {
      link.download = "autodock_ligand_sample.csv";
      link.href = "/samples/autodock/docking.csv";
    }

    link.click();
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  useEffect(() => {
    if (!doLoadJobs || !user) return;
    ////console.log(jobID)
    if (modelName) {
      http
        .get(`${JOB_SERVER_URL}/userjobs`, {
          params: {
            user_id: user.uid,
            model_name: modelName ? modelName.toLowerCase() : "",
            start: "0",
            end: "10",
          },
          headers: {
            accept: "application/json",
          },
        })
        .then((res) => {
          setDoLoadJobs(false);
          setRows(res.data);
        })
        .catch((error) => {
          setShowError(true);
          setErrorMsg(errorMessages.jobTableError);
          setDoLoadJobs(false);
          setRows([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs, modelName]);

  useEffect(() => {
    const pathSegment = pathname.split("/");
    if (pathSegment[2] == "autodock") {
      setModelName("autodock");
      setTabs(autodockTabs);
    } else if (pathSegment[2] == "unidock") {
      setModelName("unidock");
      setTabs(unidockTabs);
    }
  }, [pathname]);

  const handleSubmit = async (e: any) => {
    //console.log("submit clicked");
    setInProgress(true);
    const form = new FormData();
    // do validation

    if (!PDBFile) {
      alert("No file uploaded");
      setInProgress(false);
    }

    form.append("uid", user.uid);

    form.append("residue_name", residue);
    form.append("protein_chain", protChain);

    if (ligandsFile) {
      // submit via job system
      form.append("ligands_file", ligandsFile);
      form.append("lig_col_name", ligandColName);
      form.append("protein_file", PDBFile);

      const axiosObject: AxiosConfigType = {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      };

      if (modelName === "unidock") {
        axiosObject.params = {
          model_name: modelName,
        };
      }
      const response = await http
        .post(`${JOB_SERVER_URL}/dock/submit`, form, axiosObject)
        .then((response: any) => {
          setInProgress(false);
          setJobID(response.data.task_id);
          SetShowSubmitMsg(true);
          setDoLoadJobs(true);
        })
        .catch((error) => {
          setInProgress(false);
          setShowError(true);
          setErrorMsg(errorMessages.submitJobError);
        });
    } else {
      form.append("ligand_smiles", ligandSmiles);
      form.append("uploaded_protein_file", PDBFile);
      form.append("pdb_file", PDBFile);

      const requestUrl =
        modelName === "autodock"
          ? `https://app.moleculeai.com/autodock_api/run_docking/`
          : `https://app.moleculeai.com/unidock_api/get_docking_scores`;
      await http
        .post(requestUrl, form, {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        })
        .then((res: AxiosResponse) => {
          setInProgress(false);
          if (res.status !== 200) {
            //console.log(response.statusText);
            alert("error in getting docking data");
          }
          const result: SetStateAction<any[]> = [];
          result.push(
            modelName === "autodock"
              ? {
                  id: 0,
                  SMILES: res.data.SMILES,
                  score: res.data.docking_score,
                  pdb: res.data.interaction,
                  output: res.data.Prepared_protein_content,
                  modelName: modelName,
                }
              : {
                  id: 0,
                  SMILES: res.data.SMILES,
                  score: res.data.docking_score,
                  pdb: res.data.Prepared_protein_content,
                  output: res.data.SDF,
                  modelName: modelName,
                }
          );
          navigate(`/virtual-screening/${modelName}/results`, {
            state: {
              result: result,
            },
          });
        })
        .catch((error) => {
          setInProgress(false);
          setShowError(true);
          setErrorMsg(errorMessages.submitJobError);
        });
    }
  };

  return (
    <Box py={2}>
      {/* <Button onClick={handleDownloadSample}>Download Sample file</Button> */}

      <Grid container px={3} spacing={2} alignItems="stretch">
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          {/* <Card>
            <CardContent sx={{ pt: 2 }}>
              <Typography
                variant={"h5"}
                fontWeight={"bold"}
                style={{ marginBottom: "20px" }}
              >
                Description
              </Typography>
              <Typography>
                Docking Client, an automated docking platform, facilitates the
                virtual screening and seamless docking of lakhs of small
                molecules (ligands) to protein targets with little to no
                preparation, straight from the PDB-id of the target and the
                ligand's SMILE strings. Customized Python scripts are used to
                build the required protein and ligand’s “pdbqt” and “grid
                parameter” files, while an optimized Vina algorithm is used in
                the background to dock ligands.
              </Typography>
              <Box
                component="img"
                src="/images/dashcard-images/dock2.png"
                alt="Protein Info Visual"
                sx={{
                  width: "60%", // Reduces the width to 75% of the parent container
                  height: "auto",
                  mx: "auto", // Centers the image horizontally
                  display: "block", // Ensures the Box behaves like a block element
                  p: 2, // Adds padding around the image inside the Box
                }}
              />
            </CardContent>
          </Card> */}
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <Card sx={{ height: 1, width: "100%" }}>
            <CardContent>
              {runInProd && (
                <Grid container direction="column" className="beta-anchor">
                  <Grid item xs={12} sx={{ mt: 5 }}>
                    <Typography>
                      This is a Premium feature. Try with{" "}
                      <Button variant="contained" onClick={onBetaClick}>
                        BETA ACCESS
                      </Button>
                      <BetaModal
                        open={betaOpen}
                        openHandler={setBetaOpen}
                      ></BetaModal>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <FormControl
                fullWidth
                className={RUNTIME === "PROD" ? "blurry-bg" : ""}
              >
                {/* <Stack
                  direction="column"
                  p={2}
                  spacing={0.5}
                  sx={{ border: "1px solid #d7d6d6", borderRadius: "0.2rem" }}
                > */}
                <Stack direction="column" spacing={1.5}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Protein file
                    </Typography>
                    <Button
                      variant="text"
                      onClick={() => handleDownloadSample("protein")}
                    >
                      {"Download Sample"}
                    </Button>
                  </Box>
                  <FileUploader
                    accept={".pdb"}
                    handleFileUpload={(files) =>
                      handleFileUpload(files[0], "pdb")
                    }
                    deleteHandlerDisable={() => {
                      return false;
                    }}
                  />
                </Stack>
                <Stack direction="column" spacing={1.5}>
                  <FormControl>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Residue Name
                    </Typography>
                    {fetchingFields ? <LinearProgress /> : ""}
                    <Select
                      disabled={residues?.length === 0}
                      value={residue}
                      label="Residue"
                      onChange={(e) => setResidue(e.target.value)}
                    >
                      {residues?.map((v, i) => {
                        return (
                          <MenuItem key={i} value={v}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="column" spacing={1.5}>
                  <FormControl>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Protein Chain
                    </Typography>
                    {fetchingFields ? <LinearProgress /> : ""}
                    <Select
                      disabled={chains?.length === 0}
                      value={protChain}
                      label="Protein Chain"
                      onChange={(e) => setProtChain(e.target.value)}
                    >
                      {chains?.map((v, i) => {
                        return (
                          <MenuItem key={i} value={v}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                {/* </Stack> */}

                <Stack
                  direction="column"
                  spacing={1.5}
                  mt={1}
                  p={2}
                  sx={{ border: "1px solid #d7d6d6", borderRadius: "0.2rem" }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Ligands file
                    </Typography>
                    <Button
                      variant="text"
                      onClick={() => handleDownloadSample("ligand")}
                    >
                      {"Download Sample"}
                    </Button>
                  </Box>
                  <FileUploader
                    accept={".csv"}
                    handleFileUpload={(files) =>
                      handleFileUpload(files[0], "csv")
                    }
                    deleteHandlerDisable={() => {
                      setLigandsFile(null);
                      return false;
                    }}
                    headerSelector={true}
                    handleSelectedHeader={setLigandColName}
                  />
                  <Typography textAlign={"center"} my={1.5}>
                    -- OR --
                  </Typography>

                  <Typography component="label" htmlFor="smiles-string">
                    Enter a SMILES string
                  </Typography>
                  <TextField
                    placeholder="SMILES string"
                    fullWidth
                    id="smiles-string"
                    name="smiles-string"
                    value={ligandSmiles}
                    onChange={(e) => setLigandSmiles(e.target.value)}
                  />
                </Stack>

                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!PDBFile || (!ligandsFile && !ligandSmiles)}
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </FormControl>
              {/* <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={inProgress}
              >
                <CircularProgress color="inherit" />
              </Backdrop> */}

              <IndefiniteLoader state={inProgress} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ width: "50%", mx: "auto" }}>
        {showSubmitMsg && (
          <Alert
            onClose={() => {
              SetShowSubmitMsg(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          <CardContent>
            {doLoadJobs && <Loaders type={"table"} />}
            {!doLoadJobs && (
              <JobsTable
                jobs={rows}
                disableVisualize={false}
                setDoLoadJobs={setDoLoadJobs}
                nextUrl="visualize"
              ></JobsTable>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
}

export default DockingClient;
