import { GridColDef } from "@mui/x-data-grid";
import { convertIndianRupeeFormat } from "../ProfilePage/ProfilePageHelper";

interface PriceListType {
  id: number;
  name: string;
  price?: number;
  tenure: "Monthly" | "Yearly";
  features: string[];
  action: string;
  currency: string;
}

// priceList without recurring payment
// export const priceList:PriceListType[]=[
//     {
//     id:1,
//     name:"Students",
//     price:50,
//     tenure:"Monthly",
//     features:[
//         "Lorem ipsum dolor sit amet.",
//         "Lorem ipsum dolor sit amet consectetur adipisicing.",
//         "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
//         "Lorem ipsum dolor sit amet consectetur adipisicing."
//     ]
//     },
//     {
//     id:2,
//     name:"Professionals",
//     price:1500,
//     tenure:"Monthly",
//     features:[
//         "Lorem ipsum dolor sit amet.",
//         "Lorem ipsum dolor sit amet consectetur adipisicing.",
//         "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
//         "Lorem ipsum dolor sit amet consectetur adipisicing.",
//         "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",

//     ]
//     }
// ]

// priceList with recurring payment
export const priceList: PriceListType[] = [
  {
    id: 1,
    name: "MoleculeGen Flexi Plan",
    // price:50,
    tenure: "Monthly",
    features: [
      "Customize your Subscription Plan.",
      "Pay only for what you use, making it a cost-effective choice for targeted learning.",
      "Access to all features and updates of the selected modules.",
      "Ideal for those who need specific tools without the commitment of a full plan.",
    ],
    action: "Pricing Info",
    currency: "INR",
  },
  {
    id: 2,
    name: "MoleculeGen Power Plan",
    price: 187500,
    tenure: "Monthly",
    features: [
      "Full access to all MoleculeGen modules.",
      "One-time subscription for unlimited access.",
      "Benefit from all updates and new features across all modules.",
      "Cost-effective solution for comprehensive coverage.",
      "Ideal for extensive and ongoing research requirements.",
    ],
    action: "Subscribe Now",
    currency: "INR",
  },
];

export const priceListForUsa: PriceListType[] = [
  {
    id: 1,
    name: "MoleculeGen Flexi Plan",
    // price:50,
    tenure: "Monthly",
    features: [
      "Customize your Subscription Plan.",
      "Pay only for what you use, making it a cost-effective choice for targeted learning.",
      "Access to all features and updates of the selected modules.",
      "Ideal for those who need specific tools without the commitment of a full plan.",
    ],
    action: "Pricing Info",
    currency: "USD",
  },
  {
    id: 2,
    name: "MoleculeGen Power Plan",
    price: 2500,
    tenure: "Monthly",
    features: [
      "Full access to all MoleculeGen modules.",
      "One-time subscription for unlimited access.",
      "Benefit from all updates and new features across all modules.",
      "Cost-effective solution for comprehensive coverage.",
      "Ideal for extensive and ongoing research requirements.",
    ],
    action: "Subscribe Now",
    currency: "USD",
  },
];

export const workflowRowsIndia = [
  { id: 1, workflowName: "Protein Info", price: 7500 },
  { id: 2, workflowName: "Dr Viz", price: 7500 },
  { id: 3, workflowName: "Protein Prep", price: 7500 },
  { id: 4, workflowName: "Genie", price: 75000 },
  { id: 5, workflowName: "Screenie", price: 75000 },
  { id: 6, workflowName: "Ligand Prep", price: 7500 },
  { id: 7, workflowName: "Docking", price: 30000 },
  { id: 8, workflowName: "ADMET", price: 75000 },
  { id: 9, workflowName: "Promiscuity Filter", price: 7500 },
  { id: 10, workflowName: "Leader", price: 7500 },
];

export const workflowRowsUsa = [
  // { id: 1, workflowName: 'Protein Info', price: 100*1.5},
  // { id: 2, workflowName: 'Dr Viz', price: 100*1.5},
  // { id: 3, workflowName: 'Protein Prep', price: 100*1.5},
  // { id: 4, workflowName: 'Genie', price: 1000*1.5},
  // { id: 5, workflowName: 'Screenie', price: 1000*1.5},
  // { id: 6, workflowName: 'Ligand Prep', price: 100*1.5},
  // { id: 7, workflowName: 'Docking', price: 400*1.5},
  // { id: 8, workflowName: 'ADMET', price: 1000*1.5},
  // { id: 9, workflowName: 'Promiscuity Filter', price: 100*1.5},
  // { id: 10, workflowName: 'Leader', price: 100*1.5},

  { id: 1, workflowName: "Virtual Screening", price: 1000 * 1.5 },
  { id: 2, workflowName: "ADMET", price: 1000 * 1.5 },
  { id: 3, workflowName: "De Novo Generation", price: 1000 * 1.5 },
  { id: 4, workflowName: "MolBench", price: 1000 * 1.5 },
  { id: 5, workflowName: "Lead Optimisation", price: 400 * 1.5 },
];

export const workflowColumns: GridColDef<
  (typeof workflowRowsIndia)[number] | (typeof workflowRowsUsa)[number]
>[] = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "workflowName",
    headerName: "Workflow Name",
    width: 170,
    editable: true,
  },
  {
    field: "price",
    headerName: "Price ($)",
    width: 150,
    editable: true,
    renderCell: (params: any) => {
      return convertIndianRupeeFormat(params.value);
    },
  },
];

// Function to update headerName based on currency
export const updateHeaderNameForPrice = (
  columns: GridColDef[],
  country: string
) => {
  const currencySymbol = country === "india" ? "₹" : "$";
  const updatedColumns = columns.map((column) => {
    if (column.field === "price") {
      return {
        ...column,
        headerName: `Price (${currencySymbol})`,
      };
    }
    return column;
  });
  return updatedColumns;
};
