import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";
import {
  priceList,
  priceListForUsa,
  updateHeaderNameForPrice,
  workflowColumns,
  workflowRowsIndia,
  workflowRowsUsa,
} from "./pricingHelper";
import http from "../../net/http-common";
import { useUserAuth } from "../../context";
import { SERVER_URL } from "../../config";
// import { userWorkflowValidation } from "../../context/authentication/userWorkflowValidation";
import { DataGrid } from "@mui/x-data-grid";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { convertIndianRupeeFormat } from "../ProfilePage/ProfilePageHelper";

function Pricing() {
  const { user } = useUserAuth();

  const [workflowList, setWorkflowList] = useState<string[]>([]);
  const [flexiSubscriptionAmount, setFlexiSubscriptionAmount] =
    useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [defaultIndiaPlan, setDefaultIndiaPlan] = useState<string>(
    process.env.REACT_APP_SUPER_PLAN_INR
  );
  const [defaultUsaPlan, setDefaultUsaPlan] = useState<string>(
    process.env.REACT_APP_SUPER_PLAN_USD
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [alignment, setAlignment] = React.useState("usa");

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) setAlignment(newAlignment);
  };

  // To close dialog box
  const handleClose = () => {
    setDialogOpen(false);
    setFlexiSubscriptionAmount(0);
    setWorkflowList([]);
  };

  // handler to create plan and subscription and redirect to home page
  const purchaseSubscription = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // console.log("purchaseing...");
    setDialogOpen(false);
    setLoading(true);

    let plan_id: string = "";
    let customWorkflows: string[] = [];

    if (flexiSubscriptionAmount === 0) {
      //Super plan : asign all the workflows
      // console.log("purchasing super plan");

      plan_id = alignment === "india" ? defaultIndiaPlan : defaultUsaPlan;
      customWorkflows = [
        "Virtual Screening",
        "ADMET",
        "De Novo Generation",
        "MolBench",
        "Lead Optimisation",
      ];
    } else {
      //Custom Plan : create a new plan with calculated amount and asign user's choosen workflows
      // http://127.0.0.1:8000
      const url = `${SERVER_URL}/payment/create-razorpay-plan`;
      try {
        const response = await http.post(
          url,
          {
            uid: user.uid,
            amount:
              alignment == "india"
                ? flexiSubscriptionAmount
                : flexiSubscriptionAmount * 83, // converting price into indian ruppee because razorpay not supporting the international currency for us now
            currency: "INR",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        customWorkflows = workflowList;
        // console.log("Plan created successfully:", response.data);
        plan_id = response.data.id;
      } catch (error) {
        console.error("Error creating plan:", error);
        setErrorMsg(
          "Facing issue while creating your custom plan. Please Retry"
        );
        return;
      }
    }

    // console.log("workflows after submitting", customWorkflows);
    // console.log("plan after subbmittin", plan_id);

    // create a subscription
    try {
      const response = await http.post(
        `${SERVER_URL}/payment/create-subscription`,
        {
          uid: user.uid,
          plan_id: plan_id,
        },
        {
          headers: {
            accept: "application/json",
          },
        }
      );

      // console.log("subscription is is ", response);
      setLoading(false);
      const { id } = response.data.response;

      var options = {
        key: process.env.REACT_APP_PAYMENT, // Enter the Key ID generated from the Dashboard
        subscription_id: id, // when recurring payment implemented
        name: "Molecule AI Pvt. Ltd.", //your business name
        description: "You are going to subscribe our MoleculeAI Platform",
        image: "/images/favicon.jpg",
        // order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (res: any) {
          // console.log(res);
          setLoading(true);

          // Canceling existing subscription if present in db
          // Getting new subscription details and waiting for this to become active
          // http://localhost:8000/
          const workflowQuery = customWorkflows
            .map((workflow) => `workflows=${encodeURIComponent(workflow)}`)
            .join("&");
          http
            .get(
              `${SERVER_URL}/payment/get-razorpay-subscription/${id}?${workflowQuery}`,
              {
                params: {
                  plan_id: plan_id,
                  uid: user.uid,
                  afterpayment: true,
                  // workflows:customWorkflows
                },
              }
            )
            .then((subResponse) => {
              setLoading(false);

              if (
                subResponse.data.response.status &&
                subResponse.data.response.status === "active"
              ) {
                // if subscription got activated
                window.location.href = "/";
              } else {
                setLoading(false);
                setErrorMsg("Do not worry, Please for a mement or reload.");
              }
            })
            .catch((error) => {
              setLoading(false);
              setErrorMsg(
                "Something went wront while fetching subscription Info."
              );
            });
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: user.displayName, //your customer's name
          email: user.email,
        },
        notes: {
          address: "MoleculeAI Pvt Ltd.",
        },
        theme: {
          color: "#582FF1",
        },
      };

      // Intializing payment with given options
      var rzp1 = new (window as any).Razorpay(options);
      rzp1.on("payment.failed", function (res: any) {
        setLoading(false);
        setErrorMsg("Payment has been failed, Please Try Again");
      });
      rzp1.open();
      e.preventDefault();
    } catch (error) {
      setLoading(false);
      setErrorMsg(
        "Facing issue while creating your Subscription. Please Retry"
      );
    }
  };

  // change on rows selction
  const handleSelectionChange = (selectedRows: any) => {
    const selectedWorkflowList =
      alignment === "india" ? workflowRowsIndia : workflowRowsUsa;

    const selectedRowsData = selectedWorkflowList.filter((row: any) =>
      selectedRows.includes(row.id)
    );
    const subsWorfklowList = selectedRowsData.map(
      (row: any) => row.workflowName
    );

    // console.log("list", subsWorfklowList);

    // workflow list for user subscription
    setWorkflowList(subsWorfklowList);

    // total amount that user needs to pay for his custom subscription
    const totalAmount = selectedRowsData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    );

    // console.log("total", totalAmount);

    setFlexiSubscriptionAmount(totalAmount);
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleToggle}
          aria-label="Platform"
          sx={{
            margin: "3rem 6rem 0rem 3rem",
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          {/* <ToggleButton
            sx={{
              fontWeight: "bold",
              border: alignment === "india" ? "1px solid #582FF1" : "",
            }}
            value="india"
          >
            INR
          </ToggleButton> */}
          <ToggleButton
            sx={{
              fontWeight: "bold",
              border: alignment === "usa" ? "1px solid #582FF1" : "",
              borderLeft:
                alignment === "usa" ? "1px solid #582FF1 !important" : "",
            }}
            value="usa"
          >
            USD
          </ToggleButton>
        </ToggleButtonGroup>

        {alignment === "india" ? (
          <Grid
            container
            spacing={3}
            p={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {priceList.map((card, index) => (
              <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                <Paper elevation={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      flex: "1 0 0",
                      border: "1px solid var(--shade-200, #E7EBF9)",
                      height: "100%",
                      "&:hover": {
                        boxShadow: 7,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 3.75,
                        pb: 0,
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography variant={"h4"} fontWeight={700}>
                          {card.name}
                        </Typography>
                        <Typography
                          variant={"h4"}
                          fontWeight={700}
                          sx={{ color: "#582FF1" }}
                        >
                          {card.price
                            ? `${
                                card.currency === "USD" ? "$" : "₹"
                              }${convertIndianRupeeFormat(card.price)}/Month`
                            : ""}
                        </Typography>
                        <List>
                          {card.features.map((feature, fIndex) => (
                            <ListItem
                              key={fIndex}
                              disableGutters
                              sx={{ paddingBottom: 0 }}
                            >
                              <ListItemIcon sx={{ minWidth: "32px" }}>
                                <CheckIcon color={"primary"} />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{ fontSize: "1.2rem" }}
                                primary={feature}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{ p: 3.75, pt: 0, width: "100%", mt: "auto" }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        onClick={(e) =>
                          card.id === 1
                            ? setDialogOpen(true)
                            : purchaseSubscription(e)
                        }
                      >
                        <Typography
                          sx={{
                            textDecoration: "none",
                            color: "#582FF1",
                            fontWeight: 700,
                          }}
                          variant="h5"
                        >
                          {card.action}
                        </Typography>
                        <Typography
                          sx={{
                            textDecoration: "none",
                            color: "#582FF1",
                            fontWeight: 700,
                          }}
                        >
                          <i className="ri-arrow-right-line"></i>
                        </Typography>
                      </Button>
                    </CardActions>
                  </Card>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={3}
            p={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {priceListForUsa.map((card, index) => (
              <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                <Paper elevation={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      flex: "1 0 0",
                      border: "1px solid var(--shade-200, #E7EBF9)",
                      height: "100%",
                      "&:hover": {
                        boxShadow: 7,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 3.75,
                        pb: 0,
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography variant={"h4"} fontWeight={700}>
                          {card.name}
                        </Typography>
                        <Typography
                          variant={"h4"}
                          fontWeight={700}
                          sx={{ color: "#582FF1" }}
                        >
                          {card.price
                            ? `${
                                card.currency === "USD" ? "$" : "₹"
                              }${convertIndianRupeeFormat(card.price)}/Month`
                            : ""}
                        </Typography>
                        <List>
                          {card.features.map((feature, fIndex) => (
                            <ListItem
                              key={fIndex}
                              disableGutters
                              sx={{ paddingBottom: 0 }}
                            >
                              <ListItemIcon sx={{ minWidth: "32px" }}>
                                <CheckIcon color={"primary"} />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{ fontSize: "1.2rem" }}
                                primary={feature}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{ p: 3.75, pt: 0, width: "100%", mt: "auto" }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        onClick={(e) =>
                          card.id === 1
                            ? setDialogOpen(true)
                            : purchaseSubscription(e)
                        }
                      >
                        <Typography
                          sx={{
                            textDecoration: "none",
                            color: "#582FF1",
                            fontWeight: 700,
                          }}
                          variant="h5"
                        >
                          {card.action}
                        </Typography>
                        <Typography
                          sx={{
                            textDecoration: "none",
                            color: "#582FF1",
                            fontWeight: 700,
                          }}
                        >
                          <i className="ri-arrow-right-line"></i>
                        </Typography>
                      </Button>
                    </CardActions>
                  </Card>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          <Typography variant="h5">Workflow Pricing</Typography>
        </DialogTitle>

        <Box sx={{ height: "65vh", width: "100%" }}>
          <DataGrid
            sx={{
              fontSize: "1rem",
            }}
            rows={alignment === "india" ? workflowRowsIndia : workflowRowsUsa}
            columns={updateHeaderNameForPrice(workflowColumns, alignment)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 15, 20]}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionChange}
            disableRowSelectionOnClick
          />
        </Box>

        <DialogContent
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h5">
            {`Subscription Amount: ${
              alignment === "india" ? "₹" : "$"
            }${convertIndianRupeeFormat(flexiSubscriptionAmount)}/Month`}
          </Typography>
          <Typography style={{ color: "green", fontWeight: "bold" }}>
            {alignment === "india" &&
              flexiSubscriptionAmount >= priceList[1].price && (
                <>
                  You can save money by purchasing our{" "}
                  <span style={{ fontSize: "1.2rem" }}>Power Saver Plan</span>{" "}
                  for just{" "}
                  <span style={{ fontSize: "1.2rem" }}>
                    ₹{convertIndianRupeeFormat(priceList[1].price)}.
                  </span>
                  <br />
                  Enjoy unlimited feature access and more benefits!
                </>
              )}
            {alignment === "usa" &&
              flexiSubscriptionAmount >= priceListForUsa[1].price && (
                <>
                  You can save money by purchasing our{" "}
                  <span style={{ fontSize: "1.2rem" }}>Power Saver Plan</span>{" "}
                  for just{" "}
                  <span style={{ fontSize: "1.2rem" }}>
                    ${convertIndianRupeeFormat(priceListForUsa[1].price)}.
                  </span>
                  <br />
                  Enjoy unlimited feature access and more benefits!
                </>
              )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={(e) => purchaseSubscription(e)}
            disabled={workflowList.length === 0 ? true : false}
            style={{
              background: workflowList.length === 0 ? "#BDADF9" : "#582FF1",
              color: "#fff",
            }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
      <IndefiniteLoader state={loading} />
    </>
  );
}

export default Pricing;
