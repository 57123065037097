import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";

import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { toJpeg } from "html-to-image";

// displaying iframe , getting it from the api response
const HtmlDisplay = ({
  htmlString,
  smiles,
  srNo
}: {
  htmlString: any;
  smiles: string;
  srNo:number
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const htmlToImageConvert = () => {
    toJpeg(elementRef.current, { cacheBust: false, backgroundColor: "#fff" })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${smiles}_2d.jpg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {htmlString ? (
        <>
          <div
            ref={elementRef}
            dangerouslySetInnerHTML={{ __html: htmlString }}
            style={{
              boxSizing: "border-box",
              border: "1px dashed black",
              marginTop: "1rem",
              borderRadius: "0.25rem",
              marginBottom: "1rem",
              // margin:"1rem",
              width: "100%",
            }}
          />
          <Typography variant="h6" className="wrap-word" mb={1}>
            {smiles}
          </Typography>
          <Typography variant="h6" className="wrap-word" mb={1}>
            SR.No. {srNo}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={htmlToImageConvert}
            size="small"
          >
            Download
          </Button>
        </>
      ) : (
        <div>
          <Typography>No Interactions Found</Typography>
        </div>
      )}
    </>
  );
};

function CompareMolecules({
  openComapreDialog,
  setOpenComapreDialog,
  dataToCompare,
}: {
  openComapreDialog: boolean;
  setOpenComapreDialog: React.Dispatch<React.SetStateAction<boolean>>;
  dataToCompare: any[];
}) {
  const [tableColumns, setTableColumns] = useState<GridColDef[]>([]);
  const [tableRows, setTableRows] = useState<any>([]);

  // console.log("data to compare", dataToCompare);

  useEffect(() => {
    // preparing rows including:
    /*
        {
          interactions,smiles,id
        }
        */
    setTableRows([
      {
        ...dataToCompare[0]?.interactions,
        id: 0,
        smiles: dataToCompare[0]?.smiles,
        srNo: dataToCompare[0]?.srNo,
      },
      {
        ...dataToCompare[1]?.interactions,
        id: 1,
        smiles: dataToCompare[1]?.smiles,
        srNo: dataToCompare[1]?.srNo,
      },
    ]);
    // Merge keys from both interaction objects into a single set

    // preparing the columns
    const mergedKeys = new Set([
      ...Object.keys(
        dataToCompare[0]?.interactions ? dataToCompare[0]?.interactions : []
      ),
      ...Object.keys(
        dataToCompare[1]?.interactions ? dataToCompare[1]?.interactions : []
      ),
    ]);
    let columns: GridColDef[] = [
      {
        field: "srNo",
        headerName: "Sr.No.",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "smiles",
        headerName: "SMILES",
        headerAlign: "center",
        width: 300,
      },
    ];

    // Create columns for the DataGrid
    let DynamicColumns: GridColDef[] = Array.from(mergedKeys).map((key) => ({
      field: key,
      headerName: key.toUpperCase(),
      width: 180, // Adjust width as needed
      align: "center",
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    }));

    columns = [...columns, ...DynamicColumns];

    setTableColumns(columns);
  }, [dataToCompare]);

  return (
    <Dialog
      open={openComapreDialog}
      onClose={() => setOpenComapreDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // sx={{
      //   "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
      //     maxWidth: "80% !important",
      //   },
      //   "& .css-esw9ho": {
      //     maxWidth: "100% !important",
      //   },
      // }}
      PaperProps={{
        sx: {
          maxWidth: "80% !important",
          width: "80%",
        },
      }}
    >
      <DialogContent
        sx={{
          width: "100%",
          height: "900px",
        }}
      >
        <Typography variant="h5" mb={2}>
          2D DIAGRAM
        </Typography>

        <Grid container spacing={2} textAlign={"center"} alignItems={"stretch"}>
          <Grid item md={6} width={"100%"}>
            <HtmlDisplay
              htmlString={dataToCompare[0] ? dataToCompare[0]["2d_html"] : ""}
              smiles={dataToCompare[0] ? dataToCompare[0].smiles : ""}
              srNo={dataToCompare[0] ? dataToCompare[0].srNo : ""}

            />
          </Grid>
          <Grid item md={6} width={"100%"}>
            <HtmlDisplay
              htmlString={dataToCompare[1] ? dataToCompare[1]["2d_html"] : ""}
              smiles={dataToCompare[1] ? dataToCompare[1].smiles : ""}
              srNo={dataToCompare[1] ? dataToCompare[1].srNo : ""}

            />
          </Grid>
        </Grid>

        <Typography variant="h5" mt={2}>
          GRAPH
        </Typography>

        <Grid container spacing={2} textAlign={"center"}>
          <Grid item md={6} width={"100%"}>
            {dataToCompare[0]?.diagram ? (
              <Box
                component={"img"}
                src={`data:image/svg+xml;base64,${btoa(
                  dataToCompare[0]?.diagram
                )}`}
                className="dashIcon" //
                sx={{
                  display: "block",
                  m: "auto",
                  width: "80%",
                  height: "80%",
                  transition: "transform 0.8s ease-in-out",
                }}
              />
            ) : (
              <Typography>No Graph Found</Typography>
            )}

            <Typography variant="h6" className="wrap-word">
              {dataToCompare[0]?.smiles}
            </Typography>
            <Typography variant="h6" className="wrap-word">
              Sr.No. {dataToCompare[0]?.srNo}
            </Typography>
          </Grid>
          <Grid item md={6} width={"100%"}>
            {dataToCompare[1]?.diagram ? (
              <Box
                component={"img"}
                src={`data:image/svg+xml;base64,${btoa(
                  dataToCompare[1]?.diagram
                )}`}
                className="dashIcon" //
                sx={{
                  display: "block",
                  m: "auto",
                  width: "80%",
                  height: "80%",
                  transition: "transform 0.8s ease-in-out",
                }}
              />
            ) : (
              <Typography>No Graph Found</Typography>
            )}
            <Typography variant="h6" className="wrap-word">
              {dataToCompare[1]?.smiles}
            </Typography>
            <Typography variant="h6" className="wrap-word">
              Sr.No. {dataToCompare[1]?.srNo}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="h5" mb={2}>
          INTERACTIONS
        </Typography>
        <DataGridPro
          rows={tableRows}
          columns={tableColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          autoHeight
        />
      </DialogContent>
    </Dialog>
  );
}

export default memo(CompareMolecules);
