import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import { freeWorkflows } from "../../context/authentication/UserAuthContext";
import {
  isNotSubscribed,
  isWorkflowRestricted,
  userWorkflowValidation,
} from "../../context/authentication/userWorkflowValidation";

type Props = {
  children?: JSX.Element;
  section: string;
};

function ProtectedRoute({ children, section }: Props): JSX.Element {
  let { user, accessWorkflows, worklowValidation ,userSubscription,razorpaySubInfo} = useUserAuth();

  /*
      - If : section is not included in freeworkflow and access workflows array
            then - Redirect to home page 
      - Else If : workflows validation days are 0 and section is not included in free workflow
            then - Redirect to home page
        Else
            then - Redirect to section page.
  */

  if (!user) {
    return <Navigate to="/user/login"></Navigate>;
  }
  // else if(razorpaySubInfo && razorpaySubInfo.status!=="active" && isWorkflowRestricted(
  //   worklowValidation,
  //   section,
  //   freeWorkflows,
  //   accessWorkflows
  // ) ){ // if user not subscribed or subscription ended
  //   return <Navigate to="/pricing"></Navigate>;
  // }
  else if (
    isWorkflowRestricted(
      worklowValidation,
      section,
      freeWorkflows,
      accessWorkflows
    ) && isNotSubscribed(
      section,
      freeWorkflows,
      userSubscription,
      razorpaySubInfo
    )
  ) {
    return <Navigate to="/"></Navigate>;
  }
  return children;
}

export default ProtectedRoute;
