import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Paper,
  Slider,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useForm } from "../../app/hooks";
import { setPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import http from "../../net/http-common";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import {
  localStorageSet,
  localStorageUpdate,
  localStorageGet,
  doesLocalStorageKeyExist,
} from "../../localStorage/localStorgaeUpdate";
import { useUserAuth } from "../../context";
import { ToastContainer, toast } from "react-toastify";

interface PDBInputI {
  id: string;
  doc: string;
  sample: string;
}

function PDBInput() {
  const { user } = useUserAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pdbInput = {
    id: "",
    doc: "",
    sample: "",
    phLevel: 7.4,
    removeWater: true,
    includeHeteroatoms: false,
    agreeTerms: false,
  };
  const [values, handleChange] = useForm<PDBInputI>(pdbInput);
  const [fetchingAssembly, setFetchingAssembly] = useState(false);
  const [assembly, setAssembly] = useState("1");
  const [assemblies, setAssemblies] = useState<any>();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [submit, setSubmit] = useState(false);

  let fulfilled = false;

  const submitForm = async () => {
    // await getInfo("5dk3");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (!submit) return;
    //  //console.log('clicked', assembly)

    if (doesLocalStorageKeyExist("protein-info") && !user) {
      const counter = localStorageGet("protein-info");
      if (counter >= 2) {
        toast.error(
          "We need you to Login to  continue using this workflow. This module is always free to use, we need you to login to better understand your usage, Redirecting...",
          {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setTimeout(() => {
          navigate("/user/login");
        }, 8000);

        return;
      }
    } else if (!user) {
      localStorageSet("protein-info");
    }
    localStorageUpdate("protein-info");
    navigate("/utilities/protein-info/details", {
      state: {
        pdbID: values.id.toLowerCase(),
        assembly: assembly,
      },
    });
  };

  useEffect(() => {
    if (!values.id) return;

    if (values.id.length !== 4) return;
    setFetchingAssembly(true);
    http
      .post(
        "/protein/detectAssemblies",
        {
          pdb_id: "string",
        },
        {
          params: {
            pdb_id: values.id.toLowerCase(),
          },
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        setAssemblies(res.data);
        setFetchingAssembly(false);
        setSubmit(true);
      })
      .catch((error) => {
        ////console.log(error);
        setShowError(true);
        if (error.response) {
          if (error.response.status >= 500) {
            setErrorMsg(errorMessages.serverError);
          } else if (error.response.status >= 400) {
            setErrorMsg(errorMessages.clientError);
          } else setErrorMsg(errorMessages.genericError);
        } else if (error.request) {
          setErrorMsg(errorMessages.connectionError);
        } else {
          setErrorMsg(errorMessages.requestError);
        }
        setFetchingAssembly(false);
      });
  }, [values.id]);

  const handleSelect = (event: SelectChangeEvent) => {
    setAssembly(event.target.value as string);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid item px={3} py={2.5} sx={{ backgroundColor: "grey.100" }}>
        <i className="ri-login-box-line"></i>
        <Typography
          display={"inline-block"}
          variant={"h6"}
          sx={{ color: "grey.900" }}
          ml={1}
          fontWeight={"600"}
        >
          {"Input"}
        </Typography>
      </Grid>
      <Grid container item p={3} direction={"column"} rowSpacing={3}>
        <Grid item>
          <Typography component="label" htmlFor="pdb-id">
            Enter a PDB ID
          </Typography>
          <TextField
            placeholder="7kxo"
            fullWidth
            id="pdb-id"
            name="id"
            value={values.id.toLowerCase()}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Button
            variant="text"
            id="sample"
            name="id"
            value="7kxo"
            onClick={handleChange}
          >
            Example: 7KXO
          </Button>
        </Grid>
        <Grid item sx={{ width: 1 }}>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="assembly-select-label">Assembly</InputLabel>
            <Select
              disabled={assemblies?.length}
              value={assembly}
              label="Assembly"
              onChange={handleSelect}
            >
              {Object?.keys(assemblies ? assemblies : []).map((i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {assemblies[i]}
                  </MenuItem>
                );
              })}
            </Select>
            {fetchingAssembly ? <LinearProgress /> : ""}
          </FormControl>
        </Grid>
        <Grid container item mt={6} direction={"column"} rowSpacing={2}>
          <Grid item>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={submitForm}
            >
              {"Submit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PDBInput;
