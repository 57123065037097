interface ChemletCard {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

//  DeepPocket

export const chemletsCards: ChemletCard[] = [
  {
    label: "1. Molecular Properties",
    link: "mol-props",
    description:
      "Explore and Visualize Molecular Properties for set of molecules",
    dashIcon: "images/mol-similarity.jpeg",
    navlabel: "MolProps",
  },

  // {
  //   label: "2. Molecular Similarity",
  //   link: "mol-similarity",
  //   description: "Molecular Similarity using different metrics",
  //   dashIcon: "images/mol-similarity.jpeg",
  //   navlabel: "Molecular Similarity",
  // },

  // {
  //   label: "3. TAGMol Samples",
  //   link: "tagmol",
  //   description: "Explore a showcase of molecules generated by TAGMol",
  //   dashIcon: "images/Genie-dashcard.png",
  //   navlabel: "TAGMol",
  // },
  // {
  //   label: "4. Fragment-based Ligand Design",
  //   link: "fragments",
  //   description: "Deep Generative model for Fragment-Based Molecule Generation",
  //   dashIcon: "images/podda.png",
  //   navlabel: "Fragment-based Ligand Design",
  // },
  // {
  //   label: "5. AutoFragDiff",
  //   link: "autofragdiff",
  //   description:
  //     "Autoregressive fragment based diffusion model for target-aware ligand design",
  //   dashIcon: "images/autofragdiff.png",
  //   navlabel: "AutoFragDiff",
  // },
  {
    label: "2. Sequential Ligand Filtering",
    link: "seq-filtering",
    description:
      "Sequential Pipeline with  Physicochemical Properties, Docking, ADME and Toxicity Fitlers",
    dashIcon: "images/screenie-dashcard.png",
    navlabel: "Sequential Ligand Filtering",
  },
  // {
  //   label: "7. DeepDTA",
  //   link: "deep-dta",
  //   description:
  //     "Binding Affinity score between a protein target sequence and a ligand",
  //   dashIcon: "images/deep-dta.png",
  //   navlabel: "DeepDTA",
  // },
  // {
  //   label: "8. DeepPocket",
  //   link: "deep-pocket",
  //   description: "Ligand Binding Site Detection and Segmentation",
  //   dashIcon: "images/deep-pocket.png",
  //   navlabel: "Deep Pocket",
  // },
  {
    label: "3. Promiscuity Filter",
    navlabel: "Promiscuity Filter",
    link: "promiscuity",
    // icon: "/images/LeaderIconV2.svg",
    dashIcon: "/images/dashcard-images/promiscuity.png",
    description: "Predict potential off-target effects of small-molecule drugs",
  },
  {
    label: "4. Dr Viz",
    navlabel: "Dr Viz",
    link: "mol-viewer",
    dashIcon: "/images/dashcard-images/DrVizDashCard.png",
    description:
      "Visualize Proteins, Ligands, and their interactions all in a single interface",
  },
  {
      label: "5. Protein Info",
      navlabel: "Protein Info",
      link: "protein-info",
      dashIcon: "/images/dashcard-images/protein-info.png",
      description:
        "Get curated access to all of the protein's metadata  and several analyses",
    },
  
    {
      label: "6. Protein Prep",
      navlabel: "Protein Prep",
      link: "pdb/prep",
      description: "Intelligent protein preparation for all applications",
      dashIcon: "/images/dashcard-images/protein_prep1.png",
    },
  //    {
  //   label: "12. Leader",
  //   navlabel: "Leader",
  //   link: "models/automol",
  //   dashIcon: "/images/dashcard-images/leader-dashcard.png",
  //   description: "Perform Lead optimization using Generative AI",
  // },
  {
    label: "7. TAGMol",
    link: "genie/tagmol",
    description: "",
    dashIcon: "images/dashcard-images/tagMol-dashcard.png",
    navlabel: "TAGMol",
  },
  {
    label: "8. Ligand Prep",
    navlabel: "Ligand Prep",
    link: "ligand",
    // icon: "/images/icons/ligand-prep.svg",
    description:
      "Intelligent ligand preparation, minimization, and conformation generation for all applications",
    dashIcon: "/images/dashcard-images/ligand_prep_final.png",
  },
  {
    label: "9. Ligand Interaction Profile Toolkit",
    link: "lift",
    description:
      "Ligand Interaction Profile Toolkit for detection of interactions between macromolecules and ligands",
    dashIcon: "images/lift.png",
    navlabel: "LIFT",
  },
  // {
  //   label: "9. Lift Visualizer",
  //   link: "lift/visualizer",
  //   description: "",
  //   dashIcon: "images/dashcard-images/lift_visualization.png",
  //   navlabel: "Lift Visualizer",
  // },

];
