import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";

import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  Alert,
  TextField,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import JobsTable from "../../components/common/JobsTable";
import { FileUploader, ModelCardHeading, WorkflowLandingTabs } from "../../components";
// import DownloadIcon from "@mui/icons-material/Download";
import Loaders from "../../components/common/Loaders";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import BetaModal from "../../components/common/BetaModal";
import { JOB_SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { RUNTIME } from "../../config";
// import { setSinglesmileinData } from "../Properties/propertiesSliceV2";
import {
  fetchSingleSmileData,
  selectAdmetProperties,
  setSingleSimleForBridge,
} from "./AdmeSlice";
// import { stat } from "fs";
import http from "../../net/http-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WorkflowLandingTab } from "../../models";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          The ADME and Tox app harnesses the power of Graph Neural Networks
          (GNNs) to predict the Absorption, Distribution, Metabolism, Excretion,
          and Toxicity (ADMET) properties of molecules. It uses the
          state-of-the-art GNN architectures, with over 200 computationally
          derived molecular features as inputs to the network that provides
          unparalleled accuracy.
        </Typography>
        <Box
          component="img"
          src="/images/hero/ADME2.png"
          alt="Protein Info Visual"
          sx={{
            width: "55%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 0, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Wu, Z., Jiang, D., Wang, J., Hsieh, C.-Y., Cao, D., & Hou, T. (2021). Mining Toxicity Information from Large Amounts of Toxicity Data. Journal of Medicinal Chemistry, 64(10), 6924–6936. doi:10.1021/acs.jmedchem.1c00421
        </Typography>
        <Typography variant={"body1"} mb={1}>
          2. Wu, L., Yan, B., Han, J., Li, R., Xiao, J., He, S., & Bo, X. (11 2022). TOXRIC: a comprehensive database of toxicological data and benchmarks. Nucleic Acids Research, 51(D1), D1432–D1445. doi:10.1093/nar/gkac1074
        </Typography>
      </>
    ),
  },
];

function ADMESubmitJobs() {
  const MODEL_NAME = "adme_tox";

  const [inProgress, setInProgress] = useState(false);
  // const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [SMILESFile, setSMILESFile] = useState<File>(null);
  const [smiles, setSmiles] = useState("");
  const state = useLocation();
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);
  const [ligandColName, setLigandColName] = useState("smiles");

  // const screenieProperties = useSelector(
  //   (state: any) => state.propertiesV2.properties.singleSmileData
  // );

  const navigate = useNavigate();
  const { user } = useUserAuth();
  const dispatch = useDispatch();
  const globalDispatch = useAppDispatch();
  const { properties } = useAppSelector(selectAdmetProperties);
  const singleSmilejobStatus = properties.dataOnSingleSmile.status;

  const runInProd = RUNTIME === "PROD";

  const submitFromModel = async (data: any) => {
    await axios
      .post(`${JOB_SERVER_URL}/adme_tox/submitFromModel`, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        ////console.log(response.data);
        // setFormVisible(false);
        // setJobID(response.data.task_id);
        // setJobstatusVisible(true);
      })
      .catch((error) => {});
  };

  if (state.state?.from_model) {
    // submit to ADME
    const data = new FormData();
    data.append("uid", user.uid);
    data.append("model_name", state.state.model_name);
    data.append("job_id", state.state.job_id);
    submitFromModel(data);
  }

  const handleSubmit = async (e: any) => {
    setInProgress(true);
    const data = new FormData();
    // do validation

    data.append("uid", user.uid);

    SetShowSubmitMsg(false);
    if (!SMILESFile && !smiles) {
      alert("No file uploaded");
      setInProgress(false);
      return;
    }

    if (smiles) {
      const smileName = smiles;
      globalDispatch(fetchSingleSmileData({ smileName }));
    } else {
      data.append("test_file", SMILESFile);
      data.append("smile_string", smiles);
      data.append("smiles_column", ligandColName);

      http
        .post(`${JOB_SERVER_URL}/adme_tox/submit`, data, {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        })
        .then((response: any) => {
          setInProgress(false);
          setJobID(response.data.task_id);
          SetShowSubmitMsg(true);
          setDoLoadJobs(true);
        })
        .catch((error) => {
          setInProgress(false);
          setShowError(true);
          setErrorMsg(errorMessages.submitJobError);
        });
    }
  };

  const deleteHandlerDisable = () => {
    setSMILESFile(null);
  };

  const handleFileUpload = async (file: File, name: string) => {
    SetShowSubmitMsg(false);
    switch (name) {
      case "smiles":
        setSMILESFile(file);
        break;
      default:
        ////console.log("file type unknown");
        break;
    }
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "admet_sample_input.csv";
    link.href = "/samples/admet/input_admet.csv";
    link.click();
    ////console.log("add files to download")
  };

  const onBetaClick = () => {
    // //console.log("clicked beta access");
    setBetaOpen(true);
  };

  useEffect(() => {
    if (!doLoadJobs || !user) return;
    if (state.state?.smilename) {
      setSmiles(state.state.smilename);
      globalDispatch(
        fetchSingleSmileData({ smileName: state.state?.smilename })
      );
    }

    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box py={2}>
        {/* <Button onClick={handleDownloadSample}>Download Sample file</Button> */}
        <Grid container px={3} pt={2} spacing={2} alignItems="stretch">
          <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
          </Grid>
          <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
            <Card sx={{ height: 1, width: "100%" }}>
              <CardContent>
                <Typography variant="h5" my={1}>
                  Submit Jobs to ADMET
                </Typography>
                {runInProd && (
                  <Grid container direction="column" className="beta-anchor">
                    <Grid item xs={12} sx={{ mt: 5 }}>
                      <Typography>
                        This is a Premium feature. Try with{" "}
                        <Button variant="contained" onClick={onBetaClick}>
                          BETA ACCESS
                        </Button>
                        <BetaModal
                          open={betaOpen}
                          openHandler={setBetaOpen}
                        ></BetaModal>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <FormControl
                  fullWidth
                  className={RUNTIME === "PROD" ? "blurry-bg" : ""}
                >
                  <Stack direction="column" spacing={1.5}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mb={1}
                    >
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        SMILES file
                      </Typography>

                      <Button variant="text" onClick={handleDownloadSample}>
                        {"Download Sample"}
                      </Button>
                    </Box>

                    <FileUploader
                      accept={
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      }
                      handleFileUpload={(files) =>
                        handleFileUpload(files[0], "smiles")
                      }
                      headerSelector={true}
                      fileUploadMsg={"Supports up to 1000 molecules in a file"}
                      deleteHandlerDisable={deleteHandlerDisable}
                      handleSelectedHeader={setLigandColName}
                    />
                  </Stack>
                  <Typography textAlign={"center"} my={1.5}>
                    -- OR --
                  </Typography>

                  <Typography component="label" htmlFor="smiles-string">
                    Enter a SMILES string
                  </Typography>
                  <TextField
                    placeholder="SMILES string"
                    fullWidth
                    id="smiles-string"
                    name="smiles-string"
                    value={smiles}
                    onChange={(e) => setSmiles(e.target.value)}
                  />
                  {singleSmilejobStatus === "pending" && (
                    <>
                      <Typography mt={1}>
                      Your request is under process. You shall be notfied once done..
                      </Typography>
                      <LinearProgress
                        sx={{ marginTop: "5px" }}
                        color="success"
                      />
                    </>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      mt: 2,
                      "&.Mui-disabled": {
                        background: "#BDADF9",
                        color: "#fff",
                      },
                    }}
                    disabled={
                      singleSmilejobStatus === "pending" ||
                      (!smiles && !SMILESFile)
                    }
                  >
                    Submit
                  </Button>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid sx={{ width: "50%", mx: "auto" }}>
          {showSubmitMsg && (
            <Alert
              onClose={() => {
                SetShowSubmitMsg(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className="jobs-container"
          mt={2}
          px={3}
        >
          <Card sx={{ width: 1 }}>
            <CardContent>
              {doLoadJobs && <Loaders type={"table"} />}
              {!doLoadJobs && (
                <JobsTable
                  jobs={rows}
                  setDoLoadJobs={setDoLoadJobs}
                  nextUrl="visualize"
                  setErrorMsg={setErrorMsg}
                  setShowError={setShowError}
                ></JobsTable>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
}

export default ADMESubmitJobs;
