import { Box, Grid, Typography } from "@mui/material";
import Viewer from "../../components/common/DockViewer";
import React, { useEffect, useState } from "react";
import { Color } from "molstar/lib/mol-util/color";
import { useLocation } from "react-router-dom";

function DockingResults() {
  const state = useLocation();

  useEffect(() => {
    if (state.state.result) {
      var pdbqt1 = state.state.result[0]["pdb"];
      var pdbqt2 = state.state.result[0]["output"];

      Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true).then(
        (viewer) => {
          viewer.loadDataAndMerge([
            { data: pdbqt1, format: "pdbqt" },
            { data: pdbqt2, format: state.state.result[0].modelName==='autodock'?"pdbqt":"sdf" },
          ]);
        }
      );
    }
  }, [state]);

  return (
    <>
      <Box
        width="79%"
        p={1}
        sx={{
          margin: "auto",
          backgroundColor: "#cce5ff",
          borderRadius: 1,
          border: "1.5px solid #b8daff",
          marginBottom: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              
              width="100%"
              sx={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Typography
            className="wrap-word"
                sx={{
                  color: "#004085",
                  fontWeight: 700,
                  fontSize: 18,
                 
                }}
              >
                {state.state.result[0]["SMILES"]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "rgb(61, 32, 168);",
                fontWeight: "bold",
              }}
            >
              Docking Score : {state.state.result[0]["score"]}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          m: 0,
          pl: 1,
          pr: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          id="molviewer"
          style={{
            height: "500px",
            width: "80%",
            position: "relative",
            display: "flex", // Add display flex
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        ></div>
      </Grid>
    </>
  );
}

export default DockingResults;
