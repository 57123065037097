import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Typography, Popover, Box } from "@mui/material";

const taskNames:any={
    desert_id:"DESERT",
    tagmol_id:"TAGMol",
    unidock_1_id:"Unidock Stage 1",
    unidock_2_id:"Unidock Stage 2"
}

const statusColor:any={
    SUCCESS: {
        color: "#0d600d",
    },
    FAILURE:{
        color: "#f52015",
    },
    STARTED:{
        color: "#23ae23",
    },
    REVOKED:{
        color: "#f52015",
    },
    RECEIVED:{
      color: "black",
  },
}

function SubTaskStatus({ subtasks }: { subtasks: any }) {
  const [anchorEl, setAnchorEl] = useState(null);
//   const inputData = JSON.parse(data);
  // //console.log(inputData);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // //console.log(data);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        Status
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          {subtasks && Object.entries(subtasks).map(([key, value]) => {
            const typedValue = value as { state: string }; // Assert the type
            return (
              <Typography key={key}>
                <strong>{taskNames[key]}:</strong>  <span 

                style={{
                    color:typedValue? statusColor[typedValue.state].color :"black"
                }}
                
                >{typedValue? typedValue.state :"N/A"}</span>
              </Typography>
            );
          })}
        </Box>
   
      </Popover>
    </>
  );
}

export default SubTaskStatus;
