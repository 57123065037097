interface LeaderCardsTypes {
    label: string;
    link: string;
    description: string;
    dashIcon: string;
    navlabel: string;
  }
  
  export const leaderCards: LeaderCardsTypes[] = [
    // {
    //   label: "Interaction Adder",
    //   link: "interactions",
    //   description: "",
    //   dashIcon: "images/dashcard-images/diffmol-dashcard.png",
    //   navlabel: "Interaction Adder",
    // },
    {
      label: "Molecule CoPilot",
      link: "copilot",
      description: "Optimize molecules with interactive natural language feedback",
      dashIcon: "images/dashcard-images/molecule_copilot5.jpg",
      navlabel: "Molecule CoPilot",
    },
    {
      label: "R Group Replacements App",
      link: "r-groups",
      // icon: "/images/icons/ligand-prep.svg",
      description: "R-group replacement for drug generation and optimization",
      dashIcon: "images/r-groups.png",
      navlabel: "R Groups",
    },
  ];
  